// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  FormGroup, FormControlLabel, Checkbox,
  Modal, Fade, Paper, Backdrop
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// import contexts

// import custom components
import MotionModal from '../lists/Motion';

// import custom CSS
import '../../css/tool.css';

export default function DmgTypeInput(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [openMotionList, setOpenMotionList] = React.useState(false);

  const attack = React.useRef(0);
  const attackPlus = React.useRef(0);
  const attackMultiply = React.useRef(1);

  const weaponEattack = React.useRef(0);
  const eattackPlus = React.useRef(0);
  const eattackMultiply = React.useRef(1);
  const [eattack15, setEattack15] = React.useState(false);

  const motionVal = React.useRef(0);
  const hitZone = React.useRef(140);

  const elementCriticalModifier = React.useRef(1);
  const criticalModifier = React.useRef(1.25);
  const modifier = React.useRef(1);

  const damage = React.useRef(0);
  const criticalDamage = React.useRef(0);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    calculate();
  }, [eattack15]);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */
  const calculate = () => {
    const attackVal = Math.floor(Number(attack.current.value) * Number(attackMultiply.current.value)) + Number(attackPlus.current.value);
    const eattackMultiplier = eattack15 ? 1.5 : 1;
    const eattackVal = Math.floor(
      Math.floor((Number(weaponEattack.current.value) + Number(eattackPlus.current.value)) * Number(eattackMultiply.current.value))
      * eattackMultiplier
    );
    const criticalEattackVal = Math.floor(
      Math.floor((Number(weaponEattack.current.value) * Number(elementCriticalModifier.current.value) + Number(eattackPlus.current.value)) * Number(eattackMultiply.current.value))
      * eattackMultiplier
    );

    const _damage = (attackVal + eattackVal) * Number(motionVal.current.value) * Number(hitZone.current.value) * Number(modifier.current.value) / 10000;
    const _criticalDamage = (attackVal + criticalEattackVal)
      * Number(motionVal.current.value) * Number(hitZone.current.value) * Number(modifier.current.value) * Number(criticalModifier.current.value)
      / 10000;

    damage.current.value = Math.ceil(_damage);
    criticalDamage.current.value = Math.ceil(_criticalDamage);
  };


  /**
   *  Render the component
   */
  return (
    <Grid container={true}>
      <Grid item={true} xs={12} sx={{ marginBottom: 3 }}>

        <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
            <TextField size="small" type="number" label={t("labels.pattackValue")} onChange={calculate} inputRef={attack}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }}
              helperText="武器攻撃力+錬成パラ" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.attackPlusValue")} onChange={calculate} inputRef={attackPlus}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} defaultValue={0.00.toFixed(0)}
              helperText="フルチャ、尻上がり等" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.attackMultiplyValue")} onChange={calculate} inputRef={attackMultiply}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} defaultValue={1.00.toFixed(1)}
              helperText="連撃、守勢等" FormHelperTextProps={{ style: { fontSize: 8 } }} />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
            <TextField size="small" type="number" label={t("labels.eattackValue")} onChange={calculate} inputRef={weaponEattack}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }}
              helperText="武器の属性値" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.eattackPlusValue")} onChange={calculate} inputRef={eattackPlus}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} defaultValue={0.00.toFixed(0)}
              helperText="属性強化の値" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.eattackkMultiplyValue")} onChange={calculate} inputRef={eattackMultiply}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} defaultValue={1.00.toFixed(1)}
              helperText="凍風等" FormHelperTextProps={{ style: { fontSize: 8 } }} />
          </Stack>

          <FormGroup>
            <FormControlLabel control={<Checkbox size="small" />}
              label={<Typography variant="body2" color="grey">{t("labels.element1-5")}</Typography>}
              onChange={e => { setEattack15(e.target.checked) }} />
          </FormGroup>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
            <Stack direction="column" justifyContent="center" alignItems="center">
              <TextField size="small" type="number" label={t("labels.move") + t("labels.motionValue")} onChange={calculate} inputRef={motionVal}
                InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
              <Button variant="text" size="small" startIcon={<SearchIcon />} onClick={() => setOpenMotionList(true)}>
                {t("labels.openMotionList")}
              </Button>
            </Stack>
            <TextField size="small" type="number" label={t("labels.hitZone")} onChange={calculate} inputRef={hitZone}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} defaultValue={140.00.toFixed(0)} />
          </Stack>

          <Modal open={openMotionList} onClose={() => setOpenMotionList(false)}
            closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
            <Fade in={openMotionList}>
              <Paper className="info-modal" sx={{ boxShadow: 24 }}>
                <MotionModal setIsLoading={props.setIsLoading} />
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Button variant="text" size="small" startIcon={<CloseIcon />} onClick={() => setOpenMotionList(false)}>
                    {t("labels.closeModal")}
                  </Button>
                </Stack>
              </Paper>
            </Fade>
          </Modal>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
            <TextField size="small" type="number" label={t("labels.elementCriticalModifier")} onChange={calculate} inputRef={elementCriticalModifier}
              InputLabelProps={{ shrink: true }} InputProps={{ inputMode: "numeric" }} defaultValue={1.00.toFixed(1)}
              helperText="会心撃属性の有無" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.criticalModifier")} onChange={calculate} inputRef={criticalModifier}
              InputLabelProps={{ shrink: true }} InputProps={{ inputMode: "numeric" }} defaultValue={1.25}
              helperText="超会心の有無" FormHelperTextProps={{ style: { fontSize: 8 } }} />
            <TextField size="small" type="number" label={t("labels.damageModifier")} onChange={calculate} inputRef={modifier}
              InputLabelProps={{ shrink: true }} InputProps={{ inputMode: "numeric" }} defaultValue={1.00.toFixed(1)}
              helperText="ダメージUP系スキル倍率を加算した値" FormHelperTextProps={{ style: { fontSize: 8 } }} />
          </Stack>

          <Typography variant="h5" textAlign="center">||</Typography>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <TextField type="number" label={t("labels.damageValue")} inputRef={damage}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
            <TextField type="number" label={t("labels.criticalDamageValue")} inputRef={criticalDamage}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
          </Stack>
        </Stack>
      </Grid>

    </Grid >
  );
}