// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import 'moment/locale/ja';
import { styled } from '@mui/material/styles';
import {
  Stack,
  Collapse,
  Button,
  Table, TableBody, TableRow, TableCell,
  Typography,
} from '@mui/material';

// import contexts

// import custom components

// import custom CSS
import '../../css/motion.css';

export default function DmgSubMotion(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [collapse, setCollapse] = React.useState(true);
  const { row, bgColor, weapon, partsList, stats, misc, showCritical } = props;

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */

  const calculateDamage = (part, motion) => {
    let damage = [0, 0];
    const weaponModifier = getWeaponModifer(part, motion);

    if (motion.type === "fixed") {
      if (motion.move.includes("SP")) {
        damage[0] = weapon.sp.damage * stats.spModifier;
      } else if (motion.move.includes("竜杭砲")) {
        damage[0] = weapon.fixedDamage.wyrmstakeCannon * stats.blastModifier;
      } else if (motion.move.includes("フルバースト")) {
        damage[0] = weapon.fixedDamage.burstFire * stats.blastModifier;
      } else if (motion.move.includes("溜め砲撃")) {
        damage[0] = weapon.fixedDamage.chargedShelling * stats.blastModifier;
      } else if (motion.move.includes("砲撃")) {
        damage[0] = weapon.fixedDamage.shelling * stats.blastModifier;
      }
      damage[0] *= weaponModifier;
      damage[1] = damage[0];
    } else if (motion.attribute === "exploding") {
      const combinedAttacks = (weapon.filter.includes("impact")) ? stats.attack : stats.attack + stats.eattack;
      damage[0] = combinedAttacks * stats.blastModifier * weaponModifier
        * motion.motionValue * 130 / 10000;
      damage[1] = damage[0];

    }
    else if (motion.subMove?.length > 0) {
      damage = sumSubMoveDamages(part, motion.subMove);

    } else {
      const hitZone = part.hitZones.find(h => h.attribute === motion.attribute);
      damage[0] = (stats.attack + stats.eattack) * weaponModifier
        * motion.motionValue * hitZone.value
        / 10000;
      damage[1] = (stats.attack + stats.criticalEattack) * weaponModifier
        * motion.motionValue * hitZone.value
        / 10000;

      if (motion.type === "sp") {
        damage[0] *= stats.spModifier;
        damage[1] *= stats.spModifier;
      } else {
        damage[0] *= stats.modifier;
        damage[1] *= stats.modifier;
      }

      if (hitZone.value >= 130) {
        damage[1] *= stats.criticalModifier[1];
      } else {
        damage[1] *= stats.criticalModifier[0];
      }
    }

    return [Math.ceil(damage[0]), Math.ceil(damage[1])];
  };

  const sumSubMoveDamages = (part, subMotions) => {
    let totalDamage = [0, 0];

    subMotions.forEach(s => {
      const weaponModifier = getWeaponModifer(part, s);

      if (s.attribute === "exploding") {
        const combinedAttacks = (weapon.filter.includes("impact")) ? stats.attack : stats.attack + stats.eattack;
        let damage = combinedAttacks * stats.blastModifier * weaponModifier
          * s.motionValue * 130 * s.maxHit / 10000;
        totalDamage[0] += damage;
        totalDamage[1] += damage;

      } else {
        const hitZone = part.hitZones.find(h => h.attribute === s.attribute);
        let damage = (stats.attack + stats.eattack) * weaponModifier
          * s.motionValue * hitZone.value * s.maxHit
          / 10000;
        let criticalDamage = (stats.attack + stats.criticalEattack) * weaponModifier
          * s.motionValue * hitZone.value * s.maxHit
          / 10000;

        if (s.type === "sp") {
          damage *= stats.spModifier;
          criticalDamage *= stats.spModifier;
        } else {
          damage *= stats.modifier;
          criticalDamage *= stats.modifier;
        }

        totalDamage[0] += damage;

        if (hitZone.value >= 130) {
          totalDamage[1] += criticalDamage * stats.criticalModifier[1];
        } else {
          totalDamage[1] += criticalDamage * stats.criticalModifier[0];
        }
      }

      totalDamage = [Math.ceil(totalDamage[0]), Math.ceil(totalDamage[1])];
    });

    return totalDamage;
  };

  const getWeaponModifer = (part, motion) => {
    let weaponModifier = 1;

    misc.forEach(m => {
      switch (m.type) {
        case "monster":
          const monsterArmor = part.armor.find(a => a.type === m.label);
          if (motion.attribute !== "exploding") {
            weaponModifier *= monsterArmor.modifier;
          }
          break;

        case "weapon":
          const weaponBuff = motion.weaponBuff.find(b => b.buff === m.label);
          if (weaponBuff) {
            if (weaponBuff.buff === "shieldCharge" && motion.attribute === "exploding") {
              weaponModifier *= motion.weaponBuff[0].modifier / motion.motionValue;
            } else {
              weaponModifier *= weaponBuff.modifier;
            }
          }
          break;

        case "element":
          break;

        default:
          weaponModifier *= m.modifier;
          break;
      }
    });

    return weaponModifier;
  };

  const CollapsedRow = styled(TableRow)(({ theme }) => ({
    "&:not(:last-child) th, &:not(:last-child) td": {
      border: 0,
    }
  }));

  /**
   *  Custome variables
   */
  const nf = new Intl.NumberFormat('en-US');

  /**
   *  Render the component
   */
  return (
    <>
      <TableRow key={row._id}>
        <TableCell sx={{ backgroundColor: bgColor, minWidth: 150, position: "sticky", zIndex: 90, left: 0 }}>
          <Stack direction="column">
            <Typography variant="caption">{row.move}</Typography>
            {
              row.subMove.length > 0 ?
                <Button variant="text" size="small" sx={{ width: "100px" }} onClick={() => setCollapse(!collapse)}>
                  {t(collapse ? `labels.openDetails` : `labels.closeDetails`)}
                </Button>
                :
                <></>
            }
          </Stack>
        </TableCell>
        {
          partsList.map(p => {
            const dmg = calculateDamage(p, row);
            return (
              <TableCell align="center" key={p._id} sx={{ backgroundColor: bgColor, width: 80 }}>
                <Stack direction="column">
                  <Typography variant="body2">{nf.format(dmg[0])}</Typography>
                  {
                    showCritical ?
                      <Typography variant="body2" color="error">{nf.format(dmg[1])}</Typography>
                      :
                      <></>
                  }
                </Stack>
              </TableCell>
            )
          })
        }
      </TableRow>
      {
        row.subMove.length > 0 ?
          <TableRow key={row.subMove[0]._id}>
            <TableCell sx={{ padding: 0, border: 0 }} colSpan={partsList.length + 1}>
              <Collapse in={!collapse} timeout="auto" unmountOnExit >
                <Table size="small">
                  <TableBody>
                    {
                      row.subMove?.map(sub =>
                        <CollapsedRow key={sub._id}>
                          <TableCell component="th" scope="row" align="left" sx={{ backgroundColor: "white", minWidth: 150, position: "sticky", zIndex: 90, left: 0 }}>
                            <Stack direction="column">
                              {sub.name}
                            </Stack>
                          </TableCell>
                          {
                            partsList.map(p => {
                              const dmg = calculateDamage(p, sub);
                              return (
                                <TableCell align="center" key={p._id} sx={{ width: 80 }}>
                                  <Stack direction="column">
                                    <Typography variant="body2">{nf.format(dmg[0])}</Typography>
                                    {
                                      showCritical ?
                                        <Typography variant="body2" color="error">{nf.format(dmg[1])}</Typography>
                                        :
                                        <></>
                                    }
                                  </Stack>
                                </TableCell>
                              )
                            })
                          }
                        </CollapsedRow>
                      )
                    }
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
          :
          <></>
      }
    </>
  );
}