// import React libraries
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import third-party libraries
import { axios, AxiosContext } from '../context/axios';

// import custom components
import ProtectedRoute from '../route/protectedRoute';

import MyBackdrop from './common/Backdrop';
import Navbar from './common/Navbar'

import TopPage from './Top';
import NotificationPage from './notifications/Notification';
import ArticlePage from './articles/Article';
import ArticleListPage from './articles/ArticleList';
import MonsterPage from './monsters/Monster';
import MotionPage from './lists/Motion';
import DmgCalculatorPage from './tools/DmgCalculator';
import StatusPage from './status/Status';
import DriftstonePage from './driftstones/Driftstone';
import NightmaresSeedPage from './lists/NightmaresSeed';

import AdminPage from './admin/Admin.js';
import ImportPage from './admin/Import.js';
import EditorPage from './articles/Editor';

// import custom css
import '../css/common.css'

export default function App() {
  /**
   *  React States
   */
  const [isLoading, setIsLoading] = React.useState(true);
  const [adminToken, setAdminToken] = React.useState(null);

  /**
   *  Render the component
   */
  return (
    <AxiosContext.Provider value={axios}>
      <MyBackdrop isLoading={isLoading} />
      <Navbar />
      <Router>
        <Routes>
          <Route path="/notification/:id" element={<ArticlePage setIsLoading={setIsLoading} />} />
          <Route path="/notifications" element={<NotificationPage setIsLoading={setIsLoading} />} />
          <Route path="/monster/:monsterName" element={<MonsterPage setIsLoading={setIsLoading} />} />

          <Route path="/motions" element={<MotionPage setIsLoading={setIsLoading} showMemo={true} />} />
          <Route path="/calculator" element={<DmgCalculatorPage setIsLoading={setIsLoading} />} />
          <Route path="/status/:type?" element={<StatusPage setIsLoading={setIsLoading} />} />
          <Route path="/driftstones" element={<DriftstonePage setIsLoading={setIsLoading} />} />

          <Route path="/状態異常ボウガン必要弾数/:type?" element={<NightmaresSeedPage setIsLoading={setIsLoading} />} />

          <Route path="/admin" element={<AdminPage setIsLoading={setIsLoading} setAdminToken={setAdminToken} />} />
          <Route path="/admin" element={<ProtectedRoute adminToken={adminToken} />}>
            <Route path="import" element={<ImportPage setIsLoading={setIsLoading} adminToken={adminToken} />} />
            <Route path="createArticle" element={<EditorPage setIsLoading={setIsLoading} adminToken={adminToken} />} />
            <Route path="listArticle" element={<ArticleListPage setIsLoading={setIsLoading} adminToken={adminToken} />} />
          </Route>

          <Route path="/*" element={<TopPage setIsLoading={setIsLoading} />} />
        </Routes>
      </Router>
    </AxiosContext.Provider>
  )
}